@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&family=Silkscreen:wght@400;700&display=swap");

.os {
  font-family: "Silkscreen", cursive;
}

.content {
  font-family: "Open Sans", sans-serif;
}

.hero {
  background-image: url("../assets/pictures/Hero-Blur.png");
  background-size: cover;
  background-position: 50% 50%;
}
.contentPic {
  background-image: url("../assets/pictures/phone.png");
  background-size: cover;
  background-position: 50% 50%;
}

html {
  overflow-x: hidden;
  border-radius: 0.5rem;
}

input#image {
  position: fixed;
  top: -100px;
}

.icon {
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}
